<template>
  <BaseDialog
    title="選擇分群推播內容"
    :show="true"
    class="member-level-push-message-notice-modal"
    @close="$emit('close')"
    @confirm="goPushMessage"
  >
    <div style="margin-bottom: 26px">
      <BaseElRadioGroup v-model="pushOption">
        <div class="flex flex-col" style="gap: 8px">
          <BaseElRadio label="message">訊息推播（將前往進行訊息推播）</BaseElRadio>
          <BaseElRadio label="coupon">票券推播（將前往進行訊票券推播）</BaseElRadio>
        </div>
      </BaseElRadioGroup>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useRouter } from 'vue-router/composables'
import { get } from 'lodash'

export default defineComponent({
  name: 'MemberLevelPushMessageNoticeModal',
  components: { BaseDialog },
  props: {
    selectRow: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const router = useRouter()
    const pushOption = ref('message')

    const goPushMessage = () => {
      const data = props.selectRow
      let targetRoute = 'PushMessage'

      if (pushOption.value === 'coupon') targetRoute = 'PushCoupon'

      router.push({
        name: targetRoute,
        query: {
          tagName: get(data, 'name'),
        },
      })
    }

    return { pushOption, goPushMessage }
  },
})
</script>

<style lang="postcss" scoped>

</style>
