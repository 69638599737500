<template>
  <div class="member-level-edit-history-detail-block">
    <div class="base-info-list">
      <div
        v-for="field in displayBaseInfo"
        :key="field.value"
        class="flex"
        style="gap: 12px"
      >
        <p class="title">{{ field.label }}</p>
        <p class="value">{{ field.value }}</p>
      </div>
    </div>

    <BaseTable
      v-if="displayEditedData.length"
      :data="displayEditedData"
      style="margin-top: 8px"
    >
      <BaseElTableColumn :label="isInitRecord ? '新建項目' : '調整項目'" prop="field" />
      <BaseElTableColumn :label="isInitRecord ? '新建內容' : '調整前'" prop="before">
        <template slot-scope="scope">
          <div class="text-sub">
            <p
              v-if="
                !['conditionLevelUp', 'conditionLevelRemain'].includes(
                  scope.row.key
                )
              "
            >
              {{ get(scope, 'row.before') }}
            </p>

            <!-- 升續等條件 -->
            <div
              v-if="
                scope.row.key === 'conditionLevelUp' ||
                  scope.row.key === 'conditionLevelRemain'
              "
            >
              <div
                v-if="
                  get(scope, 'row.before.period') ||
                    get(scope, 'row.before.amount')
                "
              >
                <span v-if="get(scope, 'row.before.period')">指定期間 {{ get(scope, 'row.before.period') }} 月</span>
                <span v-if="get(scope, 'row.before.amount')">消費滿 {{ get(scope, 'row.before.amount') }}
                </span>
                <span v-if="scope.row.key === 'conditionLevelUp'">{{
                  get(scope, 'row.before.levelUpCondition') === 'onceAmount'
                    ? '次'
                    : '元'
                }}</span>
                <span v-if="scope.row.key === 'conditionLevelRemain'">{{
                  get(scope, 'row.before.levelUpCondition') === 'onceAmount'
                    ? '次'
                    : '元'
                }}</span>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="!isInitRecord" label="調整後" prop="after">
        <template slot-scope="scope">
          <div class="text-primary-100 text-sub">
            <p v-if="get(scope, 'row.defaultShow')" class="text-primary-100">
              {{ scope.row.after }}
            </p>

            <!-- 升續等管道 -->
            <ul
              v-if="
                scope.row.key === 'levelUpSources' ||
                  scope.row.key === 'levelRemainSources'
              "
            >
              <li v-if="get(scope, 'row.after.add')" class="list-disc">
                啟用：{{ get(scope, 'row.after.add') }}
              </li>
              <br>
              <li v-if="get(scope, 'row.after.disable')" class="list-disc">
                關閉：{{ get(scope, 'row.after.disable') }}
              </li>
              <p v-if="get(scope, 'row.after.default')">
                {{ get(scope, 'row.after.default') }}
              </p>
            </ul>

            <!-- 升續等條件 -->
            <div
              v-if="
                scope.row.key === 'conditionLevelUp' ||
                  scope.row.key === 'conditionLevelRemain'
              "
            >
              <div
                v-if="
                  get(scope, 'row.after.period') ||
                    get(scope, 'row.after.amount')
                "
              >
                <span v-if="get(scope, 'row.after.period')">指定期間 {{ get(scope, 'row.after.period') }} 月</span>
                <span v-if="get(scope, 'row.after.amount')">消費滿 {{ get(scope, 'row.after.amount') }}
                </span>
                <span v-if="scope.row.key === 'conditionLevelUp'">{{
                  get(scope, 'row.after.levelUpCondition') === 'onceAmount'
                    ? '次'
                    : '元'
                }}</span>
                <span v-if="scope.row.key === 'conditionLevelRemain'">{{
                  get(scope, 'row.after.levelUpCondition') === 'onceAmount'
                    ? '次'
                    : '元'
                }}</span>
              </div>
              <span v-else>-</span>
            </div>
          </div>
        </template>
      </BaseElTableColumn>
    </BaseTable>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import {
  memberLevelFieldsConfig,
  memberLevelHistoryActionTypeConfig,
} from '@/config/memberLevel'
import { get, set as setAttr, values, pick } from 'lodash'
import { useMemberLevelHistory } from '@/use/memberLevelhistory'

export default defineComponent({
  name: 'MemberLevelEditHistoryDetailBlock',
  props: {
    historyData: { type: Object, default: () => ({}) },
    level: [Number, String],
    isInitRecord: Boolean,
  },
  setup (props) {
    const {
      handleTimeRangeData,
      handleSourceData,
      handleConditionTypeData,
      handleConditionData,
    } = useMemberLevelHistory()
    const displayBaseInfo = computed(() => {
      const data = props.historyData
      const action = get(data, 'action')
      return [
        { label: '調整人員', value: get(data, 'User.name') },
        {
          label: '調整類型',
          value: get(memberLevelHistoryActionTypeConfig, `${action}.label`),
        },
        { label: '調整階層', value: `等級階層 ${props.level}` },
      ]
    })

    const displayEditedData = computed(() => {
      const fields = values(mergeFieldData())
      if (fields[0].field === 'created') {
        return {}
      }

      const data = []
      if (!fields) return data
      fields.forEach((item) => {
        if (item.field === 'validTimeRange') {
          const fieldData = handleTimeRangeData(item)
          data.push(fieldData)
        } else if (
          item.field === 'levelUpSources' ||
          item.field === 'levelRemainSources'
        ) {
          const fieldData = handleSourceData(item)
          data.push(fieldData)
        } else if (
          item.field === 'levelUpCondition' ||
          item.field === 'levelRemainCondition'
        ) {
          const fieldData = handleConditionTypeData(item)
          data.push(fieldData)
        } else if (
          item.field === 'conditionLevelUp' ||
          item.field === 'conditionLevelRemain'
        ) {
          const fieldData = handleConditionData(item)
          data.push(fieldData)
        } else {
          data.push({
            field: get(memberLevelFieldsConfig, `${item.field}.label`),
            before: get(item, 'old'),
            after: get(item, 'new'),
            defaultShow: true,
          })
        }
      })
      return data
    })

    const transformCreateData = (data) => {
      const dataFields = get(props.historyData, 'content.data')

      const res = Object.entries(dataFields).map(([key, value]) => ({
        field: key,
        newData: value,
        oldData: value,
      }))

      console.log(res)

      return res
    }
    const mergeFieldData = () => {
      let fields = get(props.historyData, 'content.updateFields')
      const dataFields = get(props.historyData, 'content.data')
      const data = {}

      if (dataFields) fields = transformCreateData(dataFields)

      if (!fields) return data
      fields.forEach((item) => {
        const field = item.field
        if (field === 'validTime') {
          if (!data.validTimeRange) data.validTimeRange = {}
          setAttr(data, 'validTimeRange.field', 'validTimeRange')
          setAttr(data, 'validTimeRange.old.time', get(item, 'oldData'))
          setAttr(data, 'validTimeRange.new.time', get(item, 'newData'))
        } else if (field === 'validTimeUnit') {
          if (!data.validTimeRange) data.validTimeRange = {}
          setAttr(data, 'validTimeRange.field', 'validTimeRange')
          setAttr(data, 'validTimeRange.old.unit', get(item, 'oldData'))
          setAttr(data, 'validTimeRange.new.unit', get(item, 'newData'))
        } else if (field === 'levelUpPeriod') {
          if (!data.conditionLevelUp) data.conditionLevelUp = {}
          setAttr(data, 'conditionLevelUp.field', 'conditionLevelUp')
          setAttr(data, 'conditionLevelUp.old.period', get(item, 'oldData'))
          setAttr(data, 'conditionLevelUp.new.period', get(item, 'newData'))
        } else if (field === 'levelUpThreshold') {
          if (!data.conditionLevelUp) data.conditionLevelUp = {}
          setAttr(data, 'conditionLevelUp.field', 'conditionLevelUp')
          setAttr(data, 'conditionLevelUp.old.amount', get(item, 'oldData'))
          setAttr(data, 'conditionLevelUp.new.amount', get(item, 'newData'))
        } else if (field === 'levelRemainPeriod') {
          if (!data.conditionLevelRemain) data.conditionLevelRemain = {}
          setAttr(data, 'conditionLevelRemain.field', 'conditionLevelRemain')
          setAttr(data, 'conditionLevelRemain.old.period', get(item, 'oldData'))
          setAttr(data, 'conditionLevelRemain.new.period', get(item, 'newData'))
        } else if (field === 'levelRemainThreshold') {
          if (!data.conditionLevelRemain) data.conditionLevelRemain = {}
          setAttr(data, 'conditionLevelRemain.field', 'conditionLevelRemain')
          setAttr(data, 'conditionLevelRemain.old.amount', get(item, 'oldData'))
          setAttr(data, 'conditionLevelRemain.new.amount', get(item, 'newData'))
        } else {
          data[field] = {
            field: field,
            old: get(item, 'oldData'),
            new: get(item, 'newData'),
          }
        }
      })

      const keys = Object.keys(memberLevelFieldsConfig)
      return pick(data, keys)
    }

    return { displayBaseInfo, displayEditedData, get }
  },
})
</script>

<style lang="postcss" scoped>
.base-info-list {
  @apply flex flex-col gap-[4px];
  @apply text-gray-100 text-sub;

  .title {
    @apply font-medium;
  }

  .value {
    @apply text-gray-60;
  }
}
</style>
