<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="等級階級" prop="level" align="center" />
      <BaseElTableColumn label="等級名稱" prop="name" align="center" />
      <BaseElTableColumn label="等級期限" prop="expiredAt" align="center" />
      <BaseElTableColumn label="會員數" prop="count" align="center" />
      <BaseElTableColumn label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="flex justify-center">
            <BaseElButton
              type="text"
              class="underline text-primary-100"
              @click="showEditHistory(scope.row)"
            >
              編輯紀錄
            </BaseElButton>

            <BaseElButton
              type="text"
              class="underline text-primary-100"
              @click="pushMessage(scope.row)"
            >
              推播
            </BaseElButton>

            <BaseElButton
              type="text"
              class="underline text-primary-100"
              @click="onRowEdit(scope.row)"
            >
              編輯
            </BaseElButton>
          </div>
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <MemberLevelPushMessageNoticeModal
      v-if="modal.pushMessage"
      :selectRow="selectRow"
      @close="modal.pushMessage = false"
    />
    <MemberLevelEditHistoryDetail
      v-if="openEditHistory"
      :level="selectLevel"
      @close="openEditHistory = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import MemberLevelPushMessageNoticeModal from './MemberLevelPushMessageNoticeModal.vue'
import MemberLevelEditHistoryDetail from './MemberLevelEditHistoryDetail.vue'
// import { DeleteDataAPI } from '@/api/module'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'DataTable',
  components: {
    EmptyBlock,
    MemberLevelPushMessageNoticeModal,
    MemberLevelEditHistoryDetail,
  },
  props: {
    tableData: { type: Array, defalt: () => [] },
    memberCount: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useTable()
    const router = useRouter()
    const modal = reactive({
      pushMessage: false,
    })
    const openEditHistory = ref(false)
    const selectLevel = ref(0)
    const selectRow = ref(null)

    const displayData = computed(() => {
      const data = props.tableData
      const unitTrans = {
        month: '個月',
      }

      return map(data, (i) => {
        const validTimeUnit = i.validTimeUnit
        const validTime = i.validTime
        let expiredAt
        if (validTimeUnit === 'forever') expiredAt = '無限期'
        else expiredAt = `${validTime} ${get(unitTrans, validTimeUnit)}`
        return {
          level: i.level,
          name: i.name,
          expiredAt,
          count:
            props.memberCount.find(({ level }) => level === i.level)?.count ||
            0,
        }
      })
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberLevelEdit',
        params: {
          id: row.level,
        },
      })
    }

    const showEditHistory = (row) => {
      openEditHistory.value = true
      selectLevel.value = row.level
    }

    const pushMessage = async (row) => {
      modal.pushMessage = true
      selectRow.value = row
    }

    return {
      modal,
      displayData,
      onRowEdit,
      pushMessage,
      showEditHistory,
      selectLevel,
      openEditHistory,
      selectRow,
    }
  },
})
</script>
